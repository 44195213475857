import React from "react";
import logo from "./assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";

const Header = () => {
  return (
    <>
      <div className="d-flex pb-2 justify-content-between header">
        <NavLink to="/">
          <img src={logo} alt="Iskcon Logo" className="iskcon-logo" />
        </NavLink>
        <div className="d-flex gap-2 align-items-center">
          <button className="btn donateBtn ml-auto">QUICK DONATE</button>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: "white", fontSize: "20px" }}
              />
            </span>
          </button>
        </div>
      </div>

      <div className="navbar-collapse collapse" id="navbarSupportedContent">
        <div className="pt-2 pb-2 nav-bar">
          <NavLink exact to="/" className="nav-link" activeClassName="active">
            HOME
          </NavLink>
          <div
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            ABOUT
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <NavLink to="/about" className="nav-link" activeClassName="active">
              <li>
                <span className="dropdown-item">ABOUT ISKCON</span>
              </li>
            </NavLink>
            <NavLink
              to="/founder"
              className="nav-link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">FOUNDER ACHARYA</span>
              </li>
            </NavLink>
            <NavLink
              to="/philosophy"
              className="nav-link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">OUR PHILOSOPHY</span>
              </li>
            </NavLink>
            <NavLink
              to="/mission"
              className="nav-link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">OUR MISSION</span>
              </li>
            </NavLink>
            <NavLink
              to="/more-about-iskcon"
              className="nav-link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">MORE ABOUT ISKCON</span>
              </li>
            </NavLink>
          </ul>

          <NavLink to="#" className="nav-link" activeClassName="active">
            TEMPLE
          </NavLink>
          <NavLink to="#" className="nav-link" activeClassName="active">
            OUR PROGRAM
          </NavLink>
          <div
            className="nav-link dropdown-toggle"
            id="donateDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            DONATE
          </div>
          <ul className="dropdown-menu" aria-labelledby="donateDropdown">
            <NavLink
              to="/nitya-seva"
              className="nav-link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">NITYA SEVA</span>
              </li>
            </NavLink>
          </ul>
          <NavLink to="#" className="nav-link" activeClassName="active">
            SHOP
          </NavLink>
          <NavLink to="#" className="nav-link" activeClassName="active">
            SAMSKARA
          </NavLink>
        </div>
      </div>

      <div className="hide">
        <div className="pt-2 pb-2 nav-bar">
          <NavLink exact to="/" className="nav-link" activeClassName="active">
            HOME
          </NavLink>
          <div
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            ABOUT
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <NavLink to="/about" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">ABOUT ISKCON</span>
              </li>
            </NavLink>
            <NavLink
              to="/founder"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">FOUNDER ACHARYA</span>
              </li>
            </NavLink>
            <NavLink
              to="/philosophy"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">OUR PHILOSOPHY</span>
              </li>
            </NavLink>
            <NavLink
              to="/mission"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">OUR MISSION</span>
              </li>
            </NavLink>
          </ul>

          <NavLink to="#" className="nav-link" activeClassName="active">
            TEMPLE
          </NavLink>
          {/* <NavLink to="#" className="nav-link" activeClassName="active">
            OUR PROGRAM
          </NavLink> */}
          <div
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            OUR PROGRAM
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">YOUTH PREACHING</span>
              </li>
            </NavLink>
            <NavLink
              to="/YouthSeminar"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">YOUTH SEMINAR</span>
              </li>
            </NavLink>
            <NavLink
              to="/Counselling"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">COUNSELLING</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">CONGRATION PREACHING</span>
              </li>
            </NavLink>
            <NavLink
              to="/BhaktiVriksha"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">BHAKTI VRISKHA</span>
              </li>
            </NavLink>
            <NavLink
              to="/BhaktiSastri"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">BHAKTI SASTRI</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">BHAGVAD GITA COURSES</span>
              </li>
            </NavLink>
            <NavLink
              to="/GitaForYou"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">GITA FOR YOU</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">CHILDREN PREACHING</span>
              </li>
            </NavLink>
            <NavLink
              to="/GopalaFunSchool"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">GOPALA FUN SCHOOL</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">CORPORATE PROGRAMMES</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">SOCIAL WORK</span>
              </li>
            </NavLink>
          </ul>

          {/* <NavLink to="#" className="nav-link" activeClassName="active">
            SEVA OPPORTUNITIES
          </NavLink> */}
          <div
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            DONATE
          </div>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <NavLink
              to="/LifeMembership"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">LIFE MEMBERSHIP</span>
              </li>
            </NavLink>
            <NavLink
              to="/nitya-seva"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">NITYA SEVA</span>
              </li>
            </NavLink>
            <NavLink
              to="/GauSeva"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">GAU SEVA</span>
              </li>
            </NavLink>
            <NavLink
              to="/food-for-life"
              className="nav-Link"
              activeClassName="active"
            >
              <li>
                <span className="dropdown-item">FOOD FOR LIFE ANNADAAN</span>
              </li>
            </NavLink>
            <NavLink to="/" className="nav-Link" activeClassName="active">
              <li>
                <span className="dropdown-item">VIGRAH SEVA</span>
              </li>
            </NavLink>
          </ul>
          <NavLink to="#" className="nav-link" activeClassName="active">
            SHOP
          </NavLink>
          <NavLink to="#" className="nav-link" activeClassName="active">
            SAMSKARA
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
