import React from 'react'
import "./styles.css"; 
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import AllPage from "./assests/pic.png"
import AllPage1 from "./assests/pic1.png"

const lifeMembershipPage = () => {
  return (
    <>
      <Header />
      <img className='w-full' src={AllPage} alt="" />
     <img className='w-full' src={AllPage1} alt="" />
     <Footer />
    </>
  )
}

export default lifeMembershipPage;